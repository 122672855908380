@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  background:rgba(40, 44, 47, 1);
}

.App {
  margin: 40px;
}



.image-overlay {
  background: rgba(255,255,255,.72);
  text-align: center;
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: absolute;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 8px 16px;
  max-width: 72%;
  text-align: center;
}


/* Location Form */
.LocationForm {
  margin: 0 auto;
  max-width: 600px;
}
  .LocationForm label, .FilterForm label {
    display: block;
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    position: relative;
  }
  .LocationForm input, .FilterForm input, .FilterForm select {
    display: block;
    width: 100%;
    padding: 8px 20px;
    margin: 0 0 16px;
    border: 1px solid #cccccc;
    border-radius: 40px;
    font-size: 24px;
    line-height: 32px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    height: 52px;
    -webkit-appearance: none;
    background: #fff;
  }
    .LocationForm .input-helper-text {
      position: absolute;
      bottom: 10px;
      right: 24px;
      color: #ababab;
    }
    .LocationForm .input-helper-text .material-icons {
      color: #4A90E2;
      vertical-align: text-bottom;
    }
  .LocationForm button, .FilterForm button {
    width: 100%;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    background-color: #4A90E2;
    padding: 10px;
    box-sizing: border-box;
    border: 0;
    border-radius: 40px;
    margin-top: 16px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    max-height: 56px;
  }


/* Match Filter Form Options */
.FilterForm {
  margin-bottom: 24px;
}
  .FilterForm select {
    display: block;
    width: 100%;
    padding: 8px 20px;
    margin: 0 0 16px;
    border: 1px solid #cccccc;
    border-radius: 40px;
    font-size: 24px;
    line-height: 32px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    -webkit-appearance: none;
  }
  .FilterForm input, .FilterForm select {
    margin: 0;
  }




/* Cards: Theaters, Movies, Matches */
.theater-list-item img, .movie-list-item img, .match-list-item img {
  width: 100%;
  border-radius: 40px;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.3), 0 2px 50px rgba(80, 108, 140, 0.64);
  display: block;
}
.match-list-item img {
  width: 50%;
  border-radius: 0;
  display: inline;
  vertical-align: top;
  height: 100%;
  box-shadow: none;
}
  img.first-match-image {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  img.second-match-image {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
.theater-list-item:hover img, .movie-list-item:hover img {
    box-shadow: 0px 2px 32px rgba(255, 255, 255, 0.1), 0px 0px 2px 1px rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
  .theater-list-item:active img, .movie-list-item:active img {
    box-shadow: 0px 2px 16px rgba(255, 255, 255, 0.1);
  }

.movie-list-item .threeDlabel {
  width: 80px;
}


/* CSS GRID */

.theater-list, .movies-list, .match-list, .FilterForm {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 24px;
}

.theater-list-item, .movie-list-item, .match-list-item {
  grid-column: span 3;
  position: relative;
  padding: 0;
}
  .match-list-item {
    grid-column: span 4;
    height: 100%;
    overflow: hidden;
  }

.col-3 {
  grid-column: span 3;
  align-self: end;
}


/* Media Queries */


@media screen and (max-width: 768px) {
  .movie-list-item, .theater-list-item, .match-list-item {
    grid-column: span 6;
  }
}
@media screen and (max-width: 480px) {
  .App {
    margin: 16px;
  }
  
  /* CSS Grids */
  .movie-list-item, .theater-list-item, .match-list-item {
    grid-column: span 12;
  }

  .FilterForm {
    grid-gap: 8px;
  }
  .FilterForm input {
    margin-bottom: 8px;
  }
  .col-3 {
    grid-column: span 6;
  }
}
